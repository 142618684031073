$(document).on('app_ready', function () {

	var parentClass = frappe.ui.form.Control.prototype.refresh;
	frappe.ui.form.Control.prototype.refresh = function () {
		parentClass.call(this);
		if(this.$input_wrapper && this.$input_wrapper.length > 0){
			let wrapper = this.$input_wrapper[0];
			if(this.value && this.value.length > 0){
				$(wrapper).find('.help-box').html("");
			}
		}
	}

    const doctypes = frappe.boot.get_doctypes;
    // frappe.ui.form.controller
    // console.log(doctypes)
    $.each(doctypes, function (i, doctype) {
        frappe.ui.form.on(doctype, {
            onload: function(frm){
                removeUnwantedButtons(frm);
            },
            refresh: function (frm) {
                // Hide standard buttons and actions
               
                // $(".standard-actions.flex").hide();
                // $("button[data-original-title=Print]").hide();
                $('.fa-globe').hide();
                removeUnwantedButtons(frm);
                // frm.page.wrapper.find(".comment-box").css({'display':'none'});
                // if (frm.timeline && frm.timeline.wrapper) {
                //     frm.timeline.wrapper.hide();
                //     frm.comment_box.comment_wrapper.hide()
                // }
                // Remove a specific button and add a 'Back' button
                frm.remove_custom_button('Quotation', 'Get Items From');
                frm.add_custom_button(__('Back'), function () {
                    frappe.set_route('List', doctype);
                }).css({
                    "color": "white",
                    "background-color":"#e52579",
                    "font-weight": "800"
                });

                // Add help box for mandatory fields with errors
                setTimeout(() => {
                    const mandatoryFieldNames = frm.meta.fields.filter(field => field.reqd).map(field => field.fieldname);
        
                    
                    updateOrAddHelpBoxForErrors();
                    
                }, 50);
            },
           
        });
        function removeUnwantedButtons(frm) {
            
            const buttonsToRemove = ['Create','View','Actions'];
            buttonsToRemove.forEach(buttonName => {
                const intervalId = setInterval(async () => {
                    let button = await frm.page.inner_toolbar.find(
                        `[data-label="${encodeURIComponent(buttonName)}"]`
                    );
                    if (button) {
                        button.remove();
                        clearInterval(intervalId);
                    }
                }, 100);
            });
        }
        function updateOrAddHelpBoxForErrors() {
            // Find all mandatory fields with the 'has-error' class
            const errorFields = document.querySelectorAll('.frappe-control.input-max-width.has-error');
        
            errorFields.forEach(fieldElement => {
                const wrapper = fieldElement.querySelector('.control-input-wrapper');
                if (wrapper) {
                    let helpBox = wrapper.querySelector('.help-box');
                    
                    // If a help box exists, update its content
                    if (helpBox) {
                        helpBox.textContent = 'This field is mandatory and must be filled correctly.';
                        helpBox.style.color = 'red'; // Highlight error message
                    } else {
                        // Create a new help box if one does not exist
                        helpBox = document.createElement('p');
                        helpBox.className = 'help-box small text-muted';
                        helpBox.textContent = 'This field is mandatory and must be filled correctly.';
                        helpBox.style.color = 'red'; // Highlight error message
                        helpBox.style.fontSize = '12px';
                        wrapper.appendChild(helpBox);
                    }
                }
            });
        }
    });
});

function updateOrAddHelpBoxForErrors(frm) {
    // Find all mandatory fields in the form
    const errorFields = frm.fields_dict;

    Object.values(errorFields).forEach(field => {
        if (field.df.reqd) {
            const wrapper = field.wrapper.querySelector('.control-input-wrapper');
            if (wrapper) {
                let helpBox = wrapper.querySelector('.help-box');
                
                if (helpBox) {
                    helpBox.textContent = 'This field is mandatory and must be filled correctly.';
                    helpBox.style.color = 'red'; // Highlight error message
                } else {
                    helpBox = document.createElement('p');
                    helpBox.className = 'help-box small text-muted';
                    helpBox.textContent = 'This field is mandatory and must be filled correctly.';
                    helpBox.style.color = 'red'; // Highlight error message
                    helpBox.style.fontSize = '12px';
                    wrapper.appendChild(helpBox);
                }
            }
        }
    });
}
