$(document).on('app_ready', function () {
    const doctypes = frappe.boot.get_doctypes;

    $.each(doctypes, function (i, doctype) {
        frappe.listview_settings[doctype] = {
           
            hide_name_column: true,
            add_fields: ["*"],
            button: {
                show: function (doc) {
                    return !!doc.name;
                },
                get_label: function () {
                    return __('View', null, 'Access');
                },
                get_description: function (doc) {
                    return __('View {0}', [doc.name]);
                },
                action: function (doc) {
                    frappe.msgprint(`View for ${doctype}: ${doc.name}`);
                    frappe.set_route("Form", doctype, doc.name);
                },
                add_class: function () {
                    return "btn btn-primary";
                }
            },
            refresh: function(listview) {
                $("use.like-icon").hide();
                $(".comment-count").hide();
                $(".frappe-timestamp").hide();
                $(".avatar-small").hide();
            },
            onload: function (listview) {
                listview.page.add_inner_button(__('Delete Selected'), function () {
                    let selected = listview.get_checked_items();
                    let doctype = listview.doctype;
                
                    if (selected.length === 0) {
                        frappe.msgprint(__('Please select at least one {0}.', [doctype]));
                        return;
                    }
                
                    frappe.confirm(
                        __('Are you sure you want to cancel and delete the selected {0}?', [doctype]),
                        function () {
                            selected.forEach(item => checkStatusAndDelete(item.name, doctype, listview));
                        }
                    );
                }).addClass('btn-danger');
            }
        };
    });


function checkStatusAndDelete(docname, doctype, listview) {
    // Fetch document status first
    frappe.call({
        method: "frappe.client.get_value",
        args: {
            doctype: doctype,
            filters: { name: docname },
            fieldname: "docstatus"
        },
        callback: function (response) {
            if (response && response.message) {
                let docstatus = response.message.docstatus;

                if (docstatus === 1) { 
                    // 1 = Submitted -> First cancel then delete
                    cancelAndDelete(docname, doctype, listview);
                } else { 
                    // 0 = Draft, 2 = Cancelled -> Directly delete
                    deleteDocument(docname, doctype, listview);
                }
            }
        }
    });
}

function cancelAndDelete(docname, doctype, listview) {
    frappe.call({
        method: "frappe.client.cancel",
        args: {
            doctype: doctype,
            name: docname
        },
        callback: function (cancel_response) {
            if (!cancel_response.exc) {
                deleteDocument(docname, doctype, listview);
            }
        }
    });
}

function deleteDocument(docname, doctype, listview) {
    frappe.call({
        method: "frappe.client.delete",
        args: {
            doctype: doctype,
            name: docname
        },
        callback: function (delete_response) {
            if (!delete_response.exc) {
                frappe.show_alert({ message: __('Deleted {0}', [docname]), indicator: 'green' });
                listview.refresh();
            }
        }
    });
}
});